<template>
    <div class="container-fluid">
        <div class="col-md-2 hidden-sm hidden-xs"></div>
        <div class="col-md-8">
            <div class="row">
                <di class="col-sm-12">
                    <div class="text-orange text-center um">
                        <i class="icofont-error"></i>
                        <h2 class="text-u">404</h2>
                    </div>
                </di>
            </div>
        </div>
        <div class="col-md-2 hidden-sm hidden-xs"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL || '/',
        }
    }
}
</script>
<style scoped>
.um {
    padding: 90px 80px;
}
.um i {
    font-size: 60px;
    margin-right: 0px;
}
</style>